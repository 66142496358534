<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title">Selecione um produto para gerar a etiqueta</div>
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <label>Produto</label>
                                    <v-select :options="products" :reduce="(products) => products" v-if="loaded"
                                        :label="'code_description'" v-model="generate.product_id" @input="quantityDefault" class="vselect" />
                                </div>
                                <div class="col-md-4 mt-2">
                                    <label>Quantidade</label>
                                    <input type="text" v-model="generate.quantity" class="form-control">
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-2 mt-2 centered">
                                    <button class="btn btn-add" @click="generateTag">Gerar Etiquetas</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title">Produtos Favoritos</div>
                            <div class="row">
                                <table class="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">Código</th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="product in favorites" :key="product.id">
                                            <th scope="row">{{ product.code }}</th>
                                            <td>{{ product.description }}</td>
                                            <td><button class="btn btn-crud edit" @click="select(product)"><i class="fas fa-hand-pointer"></i></button></td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title">Últimas Etiquetas</div>
                            <div class="row">
                                <table class="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">Código</th>
                                        <th scope="col">Gerado Em</th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="product in last_tags.last_tags" :key="product.id">
                                            <th scope="row">{{ product.product.code }}</th>
                                            <th scope="row">{{ product.created_at_f }}</th>
                                            <td>{{ product.product.description }}</td>
                                            <td><button class="btn btn-crud edit" @click="select(product.product)"><i class="fas fa-hand-pointer"></i></button></td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
    data() {
        return {
            products: [],
            favorites: [],
            last_tags: [],
            generate: {},
            loaded: true
        };
    },
    computed: {}, 
    methods: {
        select(obj){
            const self = this;
            self.loaded = false;
            self.generate.product_id = obj;

            setTimeout(() => {
                self.loaded = true;
            }, 300);
        },
        quantityDefault(obj){
            this.generate.quantity = obj.quantity_default;
        },
        generateTag() {
            const self = this;

            if (!self.generate.product_id) {
                swal("Ops!", "Selecione um produto para prosseguir", "info");
            }

            if (!self.generate.quantity) {
                swal("Ops!", "Selecione uma quantidade para prosseguir", "info");
            }

            var popup = window.open(self.generate.product_id.barcode_url + '/' + self.generate.quantity, 'popupWindow', 'width=900,height=400');

            if (popup === null || typeof popup === 'undefined') {
                swal("Ops!", "O popup foi bloqueado pelo navegador, autorize e tente novamente.", "error");
            }
        },
        getEntity: function () {
            const self = this;
            const api = self.$store.state.api + "products/?paginated=false";

            axios
                .get(api)
                .then((response) => {
                    self.products = response.data.data;
                })
                .catch((error) => {
                    self.$message(null, error.response.data, "error");
                });
        },
        getFavorites: function () {
            const self = this;
            const api = self.$store.state.api + "products/?paginated=false&favorite=1";

            axios
                .get(api)
                .then((response) => {
                    self.favorites = response.data.data;
                })
                .catch((error) => {
                    self.$message(null, error.response.data, "error");
                });
        },
        getLast: function () {
            const self = this;
            const api = self.$store.state.api + "product/last-tags";

            axios
                .post(api)
                .then((response) => {
                    self.last_tags = response.data;
                })
                .catch((error) => {
                    self.$message(null, error.response.data, "error");
                });
        },

    },
    mounted: function () {
        const self = this;

        self.getEntity();
        self.getFavorites();
        self.getLast();
    },
    components: {
        BaseCrud,
        vSelect,
    },
};
</script>
<style scoped></style>